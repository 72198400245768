window.ZPNavbarMenuToggle = function(){ 
	if($(".zp-site-mobile-menu").hasClass("zp-show-mobile")){ 
		$(".zp-site-mobile-menu").removeClass("zp-show-mobile");
	}else{ 
		$(".zp-site-mobile-menu").addClass("zp-show-mobile");
	}
}

$(function(){
	//funciones para message bar
    var current = 0,
    slides = document.getElementsByClassName("mt");

    setInterval(function() {
      for (var i = 0; i < slides.length; i++) {
      	if(slides[i])
        slides[i].style.opacity = 0;
      }
      current = (current != slides.length - 1) ? current + 1 : 0;
      if(slides[current])
      slides[current].style.opacity = 1;
    }, 5000); 

    var current2 = 0,
    slides2 = document.getElementsByClassName("gt");

    setInterval(function() {
      for (var i = 0; i < slides.length; i++) {
      	if(slides2[i])
        slides2[i].style.opacity = 0;
      }
      current2 = (current2 != slides.length - 1) ? current2 + 1 : 0;
      if(slides2[current2])
      slides2[current2].style.opacity = 1;
    }, 5000);
})